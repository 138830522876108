import React, { lazy, Suspense } from "react";
// import { graphql, withPrefix } from "gatsby";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "../components/link";

import LocationImgSlider from "../components/slider-location-img";
import EngageBanner from "../components/box-engage-banner";
import BannerTickets from "../components/banner-tickets";
import BoxIniziativa from "../components/box-iniziativa";
import BoxSponsor from "../components/box-sponsor";
import BoxHelp from "../components/box-help";
import BoxNewsletter from "../components/box-newsletter";

const Spline = lazy(() => import("@splinetool/react-spline"));

const Iic = ({ data: { articles } }) => {
  return (
    <Layout>
      <Seo
        title={`IIC`}
        description={`Intranet Italia Champions: riconoscimento annuale assegnato alle aziende che hanno implementato una funzionalità innovativa e di successo per l'azienda e per i dipendenti.`}
      />

      <div className="box-intro">
        <div className="animation-intro-wrap">
          <video
            width="1440px"
            height="720px"
            autoPlay
            muted
            loop
            playsInline
            className="animation-intro"
          >
            <source src="/assets/iic.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="box-intro__text">
          <div className="container-md">
            <div className="row">
              <div className="col-12 text-center">
                <div className="box-intro__claim">
                  <p>Intranet Italia Champions</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section section--grey-dark">
        <div id="info" className="box-article">
          <div className="container-md">
            <div className="row position-relative">
              <div className="col-12 col-lg-10 col-xl-8 offset-xl-1">
                <h2 className="box-article__title">
                  {articles.nodes?.[0].articles?.[0].articleTitle}
                </h2>
                <div className="box-article__text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        articles.nodes?.[0].articles?.[0].articleMarkdownText.childMarkdownRemark
                          ?.html,
                    }}
                  ></div>
                  {/* <div className="mt-5">
                    <a
                      href={withPrefix(
                        "/docs/IIC_2023_Modulo_per_la_presentazione_delle_candidature.docx"
                      )}
                      title="Scarica il modulo di partecipazione"
                      download="IIC_Modulo_2022.docx"
                      className="btn-link btn-link--download"
                    >
                      <span className="btn-link__circle"></span>
                      <span className="btn-link__text">
                        Scarica il <span className="d-block bold">modulo di partecipazione</span>
                      </span>
                    </a>
                  </div> */}
                </div>
              </div>
              <Suspense>
                <Spline
                  scene="https://prod.spline.design/QGRkj-hhTrTeLl-8/scene.splinecode"
                  className="animation-text animation-text--dx"
                />
              </Suspense>
            </div>
          </div>
        </div>
        <div id="candidatura" className="box-article">
          <div className="container-md">
            <div className="row position-relative">
              <div className="col-12 col-lg-10 offset-lg-2 col-xl-8 offset-xl-4">
                <h2 className="box-article__title">
                  {articles.nodes?.[0].articles?.[1].articleTitle}
                </h2>
                <div className="box-article__text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        articles.nodes?.[0].articles?.[1].articleMarkdownText.childMarkdownRemark
                          ?.html,
                    }}
                  ></div>
                </div>
              </div>
              <Suspense>
                <Spline
                  scene="https://prod.spline.design/Ld-crMSnL-EmmYOd/scene.splinecode"
                  className="animation-text animation-text--sx"
                />
              </Suspense>
            </div>
          </div>
        </div>
        <div id="giuria" className="box-article">
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-xl-7 offset-xl-1">
                <h2 className="box-article__title">
                  {articles.nodes?.[0].articles?.[2].articleTitle}
                </h2>
                <div className="box-article__text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        articles.nodes?.[0].articles?.[2].articleMarkdownText.childMarkdownRemark
                          ?.html,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="valutazione" className="box-article">
          <div className="container-md">
            <div className="row position-relative">
              <div className="col-12 col-lg-10 offset-lg-2 col-xl-8 offset-xl-4">
                <h2 className="box-article__title">
                  {articles.nodes?.[0].articles?.[3].articleTitle}
                </h2>
                <div className="box-article__text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        articles.nodes?.[0].articles?.[3].articleMarkdownText.childMarkdownRemark
                          ?.html,
                    }}
                  ></div>
                </div>
              </div>
              <Suspense>
                <Spline
                  scene="https://prod.spline.design/4uKHClU4tawI5uzq/scene.splinecode"
                  className="animation-text animation-text--sx"
                />
              </Suspense>
            </div>
          </div>
        </div>
        {/*
        <div id="timeline" className="box-article">
          <div className="container-md">
            <div className="row position-relative">
              <div className="col-12 col-xl-7 offset-xl-1">
                <h2 className="box-article__title">
                  {articles.nodes?.[0].articles?.[4].articleTitle}
                </h2>
                <div className="box-article__text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        articles.nodes?.[0].articles?.[4].articleMarkdownText.childMarkdownRemark
                          ?.html,
                    }}
                  ></div>
                </div>
              </div>
              <Suspense>
                <Spline
                  scene="https://prod.spline.design/nzpMEFQ6p2yPBhq5/scene.splinecode"
                  className="animation-text animation-text--dx"
                />
              </Suspense>
            </div>
          </div>
        </div>
        */}
        <div id="cosa-si-vince" className="box-article">
          <div className="container-md">
            <div className="row position-relative">
              <div className="col-12 col-lg-10 offset-lg-2 col-xl-8 offset-xl-4">
                <h2 className="box-article__title">
                  {articles.nodes?.[0].articles?.[5].articleTitle}
                </h2>
                <div className="box-article__text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        articles.nodes?.[0].articles?.[5].articleMarkdownText.childMarkdownRemark
                          ?.html,
                    }}
                  ></div>
                </div>
              </div>
              <Suspense>
                <Spline
                  scene="https://prod.spline.design/jYs8nIOMCpvxKeGi/scene.splinecode"
                  className="animation-text animation-text--sx"
                />
              </Suspense>
            </div>
          </div>
        </div>

        <div className="container pt-5">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center">
                <div className="section__title">
                  <span>Edizione</span> 2023
                </div>
                <div>
                  <Link to="/edizioni-passate/#iic" title="Edizione 2021" className="btn-link">
                    <span className="btn-link__circle"></span>
                    <span className="btn-link__text">
                      Vai all'<span className="d-block bold">edizione 2023</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LocationImgSlider btnCta="/edizioni-passate/#edizioni-precedenti" />
      </section>

      <EngageBanner />

      <BannerTickets />

      <BoxIniziativa />

      <BoxSponsor />

      <BoxHelp />

      <BoxNewsletter />
    </Layout>
  );
};

export default Iic;

export const query = graphql`
  query {
    articles: allContentfulArticlePage(
      filter: { entryTitle: { eq: "Intranet Italia Champions" } }
    ) {
      nodes {
        articles {
          articleTitle
          articleMarkdownText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
